import React from 'react'
import {Redirect, Route, withRouter} from 'react-router-dom'
import {getUser} from '../store/session/sessionSelector'
import {connect} from 'react-redux'

class PrivateRoutes extends React.Component {
	render() {
		const {user, component: Component, ...rest} = this.props
		return (
		  <Route
			{...rest}
			render={(props) => {
			return user ? (
			  <Component {...props} />
			) : (
			  <Redirect to='/ogma/login' />
			)
		  }}>
		  </Route>
		)
	}
}


const mapStateToProps = (state) => ({
	user: getUser(state)
})

export default withRouter(connect(
  mapStateToProps, {}
)(PrivateRoutes))


