import React, { Component } from 'react'

export default class Error404Page extends Component {
	render() {
		return (
			<div>
				<div className="row">
					<div className="col-md-12 ">
						<h1>404</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 ">
						Not found
					</div>
				</div>
			</div>
		)
	}
}
