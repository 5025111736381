import React, {Component} from 'react'
import PropTypes from 'prop-types'
import EditUserContainer from './EditUserContainer'
import {Modal} from 'react-bootstrap'

class EditUserPopin extends Component {

	render() {
		const {user} = this.props
		return (
			<Modal show={this.props.open} onHide={this.props.onClose} bsSize="large">
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-lg">
						{ user ? 'Modification de l\'utilisateur' : 'Création d\'un utilisateur'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditUserContainer onSubmitSuccess={this.props.onClose} onCancel={this.props.onClose} user={user} />
				</Modal.Body>
			</Modal>
		)
	}
}

EditUserPopin.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.string.isRequired,
		firstname: PropTypes.string,
		lastname: PropTypes.string,
		email: PropTypes.string,
	}),
	open: PropTypes.bool,
	onClose: PropTypes.func
}

export default EditUserPopin
