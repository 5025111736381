import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Glyphicon} from 'react-bootstrap'
import ConfirmModal from '../../../../components/ConfirmModal'
import connect from 'react-redux/es/connect/connect'
import {deleteUser} from '../../../../store/users/usersActions'
import {isLoadingForDeletingUser} from '../../../../store/users/usersSelector'
import EditUserPopin from './EditUserPopin'
import { FormattedMessage } from 'react-intl'

class UserMenu extends Component {
	constructor() {
		super()
		this.state = {
			openConfirm: false,
			editPopin: false
		}
	}

	onDelete = async () => {
		return await this.props.deleteUser(this.props.user)
	}

	render() {
		const { user } = this.props
		return (
		  <div className="menu-list">
			  <Button className="btn btn-secondary" onClick={() => {
				  this.setState({ openConfirm: true })
			  }}>
				  <Glyphicon glyph="remove" /> Supprimer
			  </Button>
			  <ConfirmModal
				open={this.state.openConfirm}
				title={ <FormattedMessage id="admin.users.delete.title" /> }
				content={<FormattedMessage id="admin.users.delete.content" values={{email: user.email}}/> }
				onClose={() => { this.setState({ openConfirm: false})} }
				onConfirm={ () => this.onDelete() } />
			  <Button className="btn btn-secondary" onClick={() => {
				  this.setState({ editPopin: true })
			  }}>
				  <Glyphicon glyph="edit" /> Modifier
			  </Button>
			  <EditUserPopin
			    open={this.state.editPopin}
			    onClose={() => { this.setState({ editPopin: false}) }}
				user={user}
			  />
		  </div>
		)
	}
}

UserMenu.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.string.isRequired,
		firstname: PropTypes.string,
		lastname: PropTypes.string,
		email: PropTypes.string,
	}),
	// connect
	deleteUser: PropTypes.func
}

const mapStateToProps = (state) => ({
	loadingDelete: isLoadingForDeletingUser(state)
})

export default connect(
  mapStateToProps, {
	  deleteUser,
  })(UserMenu)