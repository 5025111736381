/* eslint-disable no-restricted-syntax, no-use-before-define */

const serializeIterable = (form, key, col) => {
	for (const el of col) {
		form.append(key, el)
	}
	return form
}

const serializeValue = (form, key, value) => {
	if (typeof value !== 'string' && typeof value[Symbol.iterator] === 'function') {
		// pour les fichiers et les tableaux
		return serializeIterable(form, key, value)
	} else if (typeof value === 'object') {
		return serializeObject(form, key, value)
	}
	return form.append(key, value)
}

const serializeObject = (form, key, col) => {
	for (const key2 of Object.keys(col)) {
		if (!Number.isNaN(+key2)) {
			serializeValue(form, key, col[key2])
		} else {
			serializeValue(form, `${key}.${key2}`, col[key2])
		}
	}
	return form
}

/**
 * Transforme un objet représentant les données d'un formulaire en objet
 * de type FormData, prêt à être envoyé via un fetch, par exemple.
 *
 * L'avantage par rapport à un simple new FormData(form) est que l'on ne
 * se repose pas sur la présence d'un <form>. On peut donc l'utiliser quand
 * la représentation d'un champ de formulaire n'est pas un input standard,
 * comme les listes déroulantes de material-ui.
 *
 * @param parts
 * @returns {*}
 */
const serializeFormValues = parts => {
	const form = new FormData()
	parts.forEach(part => {
		if (part.value) {
			if (part.value instanceof FileList) {
				for (let i = 0; i < part.value.length; i++) {
					const el = part.value[i]
					form.append(part.key, el)
				}
			} else {
				// JSON
				form.append(part.key, new Blob([JSON.stringify(part.value)], { type: 'text/json' }), part.key)
			}
		}
	})
	return form
}

export default serializeFormValues
