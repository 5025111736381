import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Glyphicon, MenuItem, Nav, Navbar, NavDropdown, NavItem} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import {Link, withRouter} from 'react-router-dom'
import {getUser} from '../../store/session/sessionSelector'
import appConst from '../../constant/appConst'
import * as sessionActions from '../../store/session/sessionActions'

class HeaderMenu extends Component {

	isMenuItemActive = url => this.props.location && this.props.location.pathname && this.props.location.pathname.indexOf(url) !== -1

	logout = async () => {
		await this.props.logoutAction()
		this.props.history.push(`${appConst.boPreffix}/login`)
	}

	render() {
		const { user } = this.props
		return (
			<Navbar collapseOnSelect fluid className="navbar-ogma">
				<Navbar.Header>
					<Navbar.Brand>
						<Link to={`${appConst.boPreffix}/`}>
							Ogma Back-Office
						</Link>
					</Navbar.Brand>
					<Navbar.Toggle />
				</Navbar.Header>
				<Navbar.Collapse>
					<Nav pullLeft>
						<LinkContainer to={`${appConst.boPreffix}/users/`} className={this.isMenuItemActive(`${appConst.boPreffix}/users/`) ? 'active' : ''}>
							<NavItem eventKey={`${appConst.boPreffix}/users/`}>
								Utilisateurs
							</NavItem>
						</LinkContainer>
					</Nav>
					<Nav pullRight>
						{user &&
						<NavDropdown
						  title={
							  <span>
								  <Glyphicon glyph="user" /> {user.firstname} {user.lastname}
								  </span>
						  } id="basic-nav-dropdown">
							<MenuItem eventKey={3.1} onClick={() => this.logout()}>Déconnexion</MenuItem>
						</NavDropdown>
						}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		)
	}
}

const mapStateToProps = state => ({
	user: getUser(state)
})

export default withRouter(connect(
	mapStateToProps,
	{
		logoutAction: sessionActions.logout
	}
)(HeaderMenu))
