import * as UserService from '../../api/UserService'
import {clearSession, storeAccessToken} from '../../storage/SessionStorage'

const ACTION_NAMESPACE = '/LOGIN'

export const types = {
	LOADING: `${ACTION_NAMESPACE}/LOADING`,
	REGISTER_USER_REDIRECTION: `${ACTION_NAMESPACE}/REGISTER_USER_REDIRECTION`,
	LOGIN: `${ACTION_NAMESPACE}/LOGIN`,
	LOGIN_LOADING: `${ACTION_NAMESPACE}/LOGIN_LOADING`,
	GET_ME: `${ACTION_NAMESPACE}/GET_ME`,
	GET_ME_LOADING: `${ACTION_NAMESPACE}/GET_ME_LOADING`,
	UPDATE_ME: `${ACTION_NAMESPACE}/UPDATE_ME`,
	UPDATE_ME_LOADING: `${ACTION_NAMESPACE}/UPDATE_ME_LOADING`,
	LOGOUT: `${ACTION_NAMESPACE}/LOGOUT`
}

export const registerRedirection = (path) => (dispatch) => {
	dispatch({
		type: types.REGISTER_USER_REDIRECTION,
		path
	})
}

export const login = (email, password) => async (dispatch) => {
	dispatch({type: types.LOGIN_LOADING, payload: { loading: true }})
	try {
		const response = await UserService.login(email, password)
		dispatch({type: types.LOGIN_LOADING, payload: {loading: false}})
		if (response) {
			const {accessToken, user} = response
			if (accessToken) {
				dispatch({
					type: types.LOGIN,
					payload: {
						accessToken,
						user
					}
				})
				storeAccessToken(accessToken)
			}
		}
		return response
	} catch (e) {
		dispatch({type: types.LOGIN_LOADING, payload: {loading: false}})
		throw e
	}
}

export const getMe = (token) => async (dispatch) => {
	dispatch({type: types.GET_ME_LOADING, payload: { loading: true }})
	try {
		const user = await UserService.getMe(token)
		dispatch({type: types.GET_ME_LOADING, payload: {loading: false}})
		if (user) {
			dispatch({
				type: types.GET_ME,
				payload: {
					user
				}
			})
		}
		return user
	} catch (e) {
		dispatch({type: types.GET_ME_LOADING, payload: {loading: false}})
		console.log('getMe exception')
		throw e
	}
}

export const updateMe = (token, userValues) => async (dispatch) => {
	dispatch({type: types.UPDATE_ME_LOADING, payload: { loading: true }})
	try {
		const user = await UserService.updateMe(token, userValues)
		dispatch({type: types.UPDATE_ME_LOADING, payload: {loading: false}})
		if (user) {
			dispatch({
				type: types.UPDATE_ME,
				payload: user
			})
		}
		return user
	} catch (e) {
		dispatch({type: types.UPDATE_ME_LOADING, payload: {loading: false}})
		throw e
	}
}

export const fakeLogin = (user) => (dispatch) => {
	dispatch({
		type: types.GET_ME,
		payload: {
			user
		}
	})
}


export const logout = () => async (dispatch) => {
	clearSession()
	return dispatch({ type: types.LOGOUT })
}