import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Table} from 'react-bootstrap'
import UserMenu from './UserMenu'

class UserList extends Component {
	constructor() {
		super()
		this.state = {
			openConfirm: false
		}
	}

	onDelete = () => {
	}


	render() {
		const { users } = this.props
		return (
		  <div>
			  <Table responsive>
				  <thead>
				  <tr>
					  <th>Email</th>
					  <th>Nom</th>
					  <th>Prenom</th>
					  <th>Action</th>
				  </tr>
				  </thead>
				  <tbody>
				  { users && users.map((user, index) => (
					  <tr key={index}>
						  <td>{user.email}</td>
						  <td>{user.firstname}</td>
						  <td>{user.lastname}</td>
						  <td className="action">
							  <UserMenu user={user} />
						  </td>
					  </tr>
					)
				  )}
				  </tbody>
			  </Table>
			  { (!users || users.length === 0) &&
			  <p>Aucun enregistrement</p>
			  }
		  </div>
		)
	}
}


UserList.propTypes = {
	users: PropTypes.array,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func
}

export default UserList
