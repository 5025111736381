// import {createSelector} from 'reselect'

export const getUser = state => (
  state.webservices.session &&
  state.webservices.session.user
)

export const isUserUpdateLoading = state => (
  state.webservices.session &&
  state.webservices.session.updateUserLoading
)

export const isUserLoading = state => (
  state.webservices.session &&
  state.webservices.session.userLoading
)


