import React from 'react'
import {Route} from 'react-router-dom'

const LoginLayout = ({component: Component, ...rest}) => {
	return (
	  <Route {...rest} render={matchProps => (
	    <div className="container-fluid login-main-container">
		    <div className="container login-content">
			<Component {...matchProps} />
		    </div>
		</div>
	  )}
	  />
	)
}

export default LoginLayout