import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import TextInputField from '../../components/form/TextField'
import {Button} from 'react-bootstrap'


class LoginForm extends Component {
	render() {
		const {onSubmit, submitting, handleSubmit, error} = this.props
		return (
		  <form onSubmit={handleSubmit} id={this.props.form}>
			  <div>
				  <Field name="login" component={TextInputField} label="Identifiant" type="text"/>
			  </div>
			  <div>
				  <Field name="password" component={TextInputField} label="Mot de passe" type="password"/>
			  </div>
			  <div style={{marginTop: 50}}>
				  <Button type="submit" block bsStyle="primary" onClick={handleSubmit(onSubmit)}>
					  {submitting ? 'Chargement...' : 'Connexion'}
				  </Button>
			  </div>
			  {
				  error &&
				  <p className="error">
					  {error}
				  </p>
			  }
		  </form>
		)
	}
}

const validate = (values) => {
	const errors = {}
	if (!values.login) {
		errors.login = 'Champ obligatoire'
	}
	if (!values.password) {
		errors.password = 'Champ obligatoire'
	}
	return errors
}

export default reduxForm({
	form: 'loginForm',
	validate
})(LoginForm)