import * as UserService from '../../api/UserService'
import {getAccessToken} from '../../storage/SessionStorage'
import {
	getUserById,
	getUserList,
	isLoadingForDeletingUser,
	isLoadingForUpdatingUser,
	isLoadingUserById,
	isLoadingUserList,
} from './usersSelector'

const ACTION_NAMESPACE = '/USERS'

export const types = {
	GET_LIST: `${ACTION_NAMESPACE}/GET_LIST`,
	LOADING_GET_LIST: `${ACTION_NAMESPACE}/LOADING_GET_LIST`,
	GET_USER_BY_ID: `${ACTION_NAMESPACE}/GET_USER_BY_ID`,
	LOADING_GET_USER_BY_ID: `${ACTION_NAMESPACE}/LOADING_GET_USER_BY_ID`,
	LOADING_UPDATE_USER_BY_ID: `${ACTION_NAMESPACE}/LOADING_UPDATE_USER_BY_ID`,
	LOADING_DELETE_USER_BY_ID: `${ACTION_NAMESPACE}/LOADING_DELETE_USER_BY_ID`,
}

/**
 * Charge la liste des users dans le store
 * @returns {Function}
 */
export const loadUserList = () => async (dispatch, getState) => {
	if (!isLoadingUserList(getState())) {
		dispatch({type: types.LOADING_GET_LIST, payload: { loading: true }})
		const token = getAccessToken()
		try {
		const users = await UserService.getList(token)
		dispatch({type: types.LOADING_GET_LIST, payload: { loading: false }})
		if (users && users.length > 0) {
			dispatch({
				type: types.GET_LIST,
				payload: {
					value: users
				}
			})
		} else {
			dispatch({
				type: types.GET_LIST,
				payload: {
					value: []
				}
			})
		}
			return users
		} catch (e) {
			dispatch({type: types.LOADING_GET_LIST, payload: { loading: false }})
			throw e
		}
	} else {
		return getUserList()(getState())
	}
}

/**
 * Charge un user selon son id dans le store
 * @returns {Function}
 */
export const loadUserById = (idUser) => async (dispatch, getState) => {
	if (!isLoadingUserById(idUser)(getState())) {
		dispatch({type: types.LOADING_GET_USER_BY_ID, payload: { loading: true, id: idUser }})
		const token = getAccessToken()
		try {
			const user = await UserService.getUser(idUser, token)
			dispatch({type: types.LOADING_GET_USER_BY_ID, payload: { loading: false, id: idUser }})
			dispatch({
				type: types.GET_USER_BY_ID,
				payload: {
					id: idUser,
					value: user
				}
			})
			return user
		} catch (e) {
			dispatch({type: types.LOADING_GET_USER_BY_ID, payload: { loading: false, id: idUser }})
			throw e
		}
	} else {
		return getUserById(idUser)(getState())
	}
}


/**
 * Update les informations d'un user
 * @returns {Function}
 */
export const updateUser = (idUser, user) => async (dispatch, getState) => {
	if (!idUser) {
		console.error('ID is required to update')
		return null
	}
	console.log('updateUser')
	if (!isLoadingForUpdatingUser(idUser)(getState())) {
		dispatch({type: types.LOADING_UPDATE_USER_BY_ID, payload: { loading: true, id: idUser }})
		const token = getAccessToken()
		try {
			const udpatedUser = await UserService.updateUser(idUser, user, token)
			console.log('updateUser', updateUser)
			dispatch({type: types.LOADING_UPDATE_USER_BY_ID, payload: { loading: false, id: idUser }})
			dispatch(loadUserList())
			return udpatedUser
		} catch (e) {
			dispatch({type: types.LOADING_UPDATE_USER_BY_ID, payload: { loading: false, id: idUser }})
			throw e
		}
	} else {
		console.log('Update already submit for this user')
		return null
	}
}

/**
 * Update les informations d'un user
 * @returns {Function}
 */
export const deleteUser = (user) => async (dispatch, getState) => {
	const {id: idUser} = user
	if (!idUser) {
		console.error('ID is required to delete')
		return null
	}
	if (!isLoadingForDeletingUser(idUser)(getState())) {
		dispatch({type: types.LOADING_DELETE_USER_BY_ID, payload: { loading: true, id: idUser }})
		const token = getAccessToken()
		try {
			await UserService.deleteUser(idUser, token)
			dispatch({type: types.LOADING_DELETE_USER_BY_ID, payload: { loading: false, id: idUser }})
			dispatch(loadUserList())
			return user
		} catch (e) {
			dispatch({type: types.LOADING_DELETE_USER_BY_ID, payload: { loading: false, id: idUser }})
			throw e
		}
	} else {
		console.log('Delete already submit for this user')
		return null
	}
}

/**
 * Create user
 * @returns {Function}
 */
export const createUser = (user) => async (dispatch, getState) => {
	try {
		const token = getAccessToken()
		const createdUser = await UserService.createUser(user, token)
		dispatch(loadUserList())
		return createdUser
	} catch (e) {
		console.error('Creation failed', e)
		throw e
	}
}