import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import session from './session/sessionReducer'
import users from './users/usersReducer'
import notifierReducer from '../pages/notifier/NotifierReducer'
import { intlReducer } from 'react-intl-redux'

const notifications = notifierReducer()

const webservices = combineReducers({
	session,
	users
})

/*
const pages = combineReducers({
})
*/

const rootReducer = combineReducers({
	/*pages,*/
	webservices,
	notifications,
	intl: intlReducer,
	form: formReducer     // <---- Mounted at 'form'
})

export default rootReducer
