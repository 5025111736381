import {get, post, put, remove} from './Service'

/**
 * ================
 * CRUD
 * =================
 */
export const getList = (token) => {
	return get(`/users/`, null, token)
}

export const getUser = (idUser, token) => {
	return get(`/users/${idUser}`, null, token)
}

export const deleteUser = (idUser, token) => {
	return remove(`/users/${idUser}`, null, token)
}

export const createUser = (values, token) => {
	return post(`/users`, values, token)
}

export const updateUser = (idUser, values, token) => {
	return put(`/users/${idUser}`, values, token)
}

/**
 *
 * @param email
 * @param password
 * @returns {Promise<*>} accessToken
 */
export const login = (email, password) => {
    return post('/users/auth', {
        email,
        password
    }, null)
}

/**
 * Renvoit les information du comte connecté
 * @param token
 * @returns {Promise<*>}
 */
export const getMe = (token) => {
    return get(`/users/me?time=${+new Date()}`, null, token)
}

/**
 * %ets à jour les informations de l'utilisateur authentifié
 * @param token
 * @param firstname
 * @param lastname
 * @param dateNaissance
 * @returns {Promise<*>}
 */
export const updateMe = (token, user) => {
	// console.log(token)
	return put('/users/me', user, token)
}