import React from 'react'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import LoginPage from '../pages/login/LoginPage'
import PrivateRoutes from './PrivateRoutes'
import Error404Page from '../pages/error/Error404Page'
import MainLayout from '../pages/layout/MainLayout'
import HomePage from '../pages/home/HomePage'
import LoginLayout from '../pages/layout/LoginLayout'
import UserPage from '../pages/admin/users/UserPage'

/* Routes d'adminisrtration (private) */
const AdminRoutes = () => (
  <div>
	  <Switch>
		  <MainLayout exact path="/ogma/" component={HomePage}/>
		  <MainLayout exact path="/ogma/home" component={HomePage}/>
		  <MainLayout exact path="/ogma/users" component={UserPage}/>
		  <MainLayout component={Error404Page}/>
	  </Switch>
  </div>
)

/* Routes principales de l'application */
const AppRouter = () => {
	return (
	  <Router>
		  <Switch>
			  <LoginLayout exact path="/ogma/login" component={LoginPage}/>
			  <PrivateRoutes path="/ogma" component={AdminRoutes}/>
			  <Redirect from="/" to="/ogma/" />
			  <Route component={Error404Page}/>
		  </Switch>
	  </Router>
	)
}

export default AppRouter