import React, { Component } from 'react'

export default class Home extends Component {
	render() {
		return (
			<div>
				<div className="row">
					<div className="col-md-12 ">
						<h1>Ogma BackOffice</h1>
						<div className="row">
							<div className="col-md-12">
								<p>
									Administration
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
				</div>
			</div>
		)
	}
}