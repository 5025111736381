import React, {Component} from 'react'
import LoginForm from './LoginForm'
import {connect} from 'react-redux'
import {SubmissionError} from 'redux-form'
import * as sessionActions from '../../store/session/sessionActions'
import {isUserLoading} from '../../store/session/sessionSelector'
import appConst from '../../constant/appConst'
import {withRouter} from 'react-router-dom'

class LoginPage extends Component {
	onLogin = async ({login, password}) => {
		try {
			await this.props.loginAction(login, password)
			this.props.history.push(`${appConst.boPreffix}/home`)
		} catch (e) {
			let error = 'Une erreur technique est survenue'
			if (e && e.status === 403) {
				error = 'Mauvais couple identifiant / mot de passe'
			}
			throw new SubmissionError({
				...e.data,
				_error: error
			})
		}
	}

	render() {
		return (
		  <div className="row">
			  <div className="col-sm-8 col-sm-offset-2">
				  <div className="login-frame">
					  <div className="login-frame-title title2">
						  Ogma Back-office
					  </div>
					  <div className="login-frame-form">
					    <LoginForm onSubmit={this.onLogin}/>
					  </div>
				  </div>
			  </div>
		  </div>
		)
	}
}


const mapStateToProps = (state, props) => ({
	loading: isUserLoading(state)
})

export default withRouter(connect(
  mapStateToProps,
  {
	  loginAction: sessionActions.login
  }
)(LoginPage))