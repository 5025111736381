import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {SubmissionError} from 'redux-form'
import UserForm from './UserForm'
import {createUser, loadUserById, updateUser} from '../../../../store/users/usersActions'
import {addNotification} from '../../../notifier/NotifierActions'

class EditUserContainer extends Component {

	handleSubmit = async (values) => {
		try {
			if (this.props.user) {
				// modification
				await this.props.updateUser(this.props.user.id, values)
			} else {
				// creation
				await this.props.createUser(values)
			}
			this.props.addNotification({
				message: (<div className="notification-content">
					{this.props.user ? 'Modification OK' : 'Création OK'}
				</div>),
				level: 'success'
			})
			this.props.onSubmitSuccess()
		} catch (e) {
			if (e.data) {
				throw new SubmissionError(e.data)
			}
			throw new SubmissionError({_error: 'Une erreur technique s\'est produite'})
		}
	}

	render() {
		const { user} = this.props

		return (
		  <div className="container-fluid">
			  <div className="row">
				  <div className="col-md-12">
					  <UserForm
						onCancel={this.props.onCancel}
						onSubmit={this.handleSubmit}
						initialValues={user}
					  />
				  </div>
			  </div>
		  </div>
		)
	}
}

EditUserContainer.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.string.isRequired,
		firstname: PropTypes.string,
		lastname: PropTypes.string,
		email: PropTypes.string,
	}),
	onCancel: PropTypes.func,
	onSubmitSuccess: PropTypes.func,
	// connect
	addNotification: PropTypes.func,
	loadUserById: PropTypes.func,
	createUser: PropTypes.func,
	updateUser: PropTypes.func
}

const mapStateToProps = () => ({
})

export default connect(
  mapStateToProps, {
	  loadUserById,
	  createUser,
	  updateUser,
	  addNotification
})(EditUserContainer)
