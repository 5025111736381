import React, {Component} from 'react'
import configureStore from './store/configureStore'
import {Provider} from 'react-redux'
import './resources/css/bootswatch/superhero/theme.scss'
import './resources/css/styles.js'
import {getAccessToken, loadAccessToken} from './storage/SessionStorage'
import {getMe} from './store/session/sessionActions'
import Loader from './components/Loader'
import AppRouter from './router/AppRouter'
import 'intl'
import 'intl/locale-data/jsonp/fr'
import {addLocaleData, IntlProvider} from 'react-intl'
import fr from 'react-intl/locale-data/fr'
import messages from './messages/fr'

// Init Intl
addLocaleData(fr)
const formats = {
	date: {
		short: {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		},
		full: {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			weekday: 'long'
		},
		datetime: {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		},
		monthYear: {
			month: 'long',
			year: 'numeric'
		},
		month: {
			month: 'long'
		}
	}
}

const store = configureStore({
	intl: {
		locale: 'fr',
		formats: {
			...formats
		},
		defaultLocale: 'fr',
		defaultFormats: {
			...formats
		}
	}
})

class App extends Component {

	constructor() {
		super()
		this.state = {
			ready: false
		}
	}

	componentWillMount() {
		this.onStartUp()
	}

	onStartUp = async () => {
		// recuperation de l'ancienne session
		await loadAccessToken()
		const token = getAccessToken()
		if (token) {
			try {
				await store.dispatch(getMe(token))
			} catch (e) {
				console.log(e)
			}
		}
		this.setState({
			ready: true
		})
	}

	render() {
		if (!this.state.ready) {
			return <div>
				<Loader />
			</div>
		}
		return (
		  <Provider store={store}>
			  <IntlProvider locale="fr" messages={messages}>
				<AppRouter />
			  </IntlProvider>
		  </Provider>
		)
	}
}

export default App
