import React  from 'react'
import HeaderMenu from './HeaderMenu'
import NotifierComponent from '../notifier/NotifierComponent'
import {Route} from 'react-router-dom'

const MainLayout = ({component: Component, ...rest}) => {
	return (
	  <Route {...rest} render={matchProps => (
	    <div>
		    <header>
			    <HeaderMenu />
		    </header>
		    <NotifierComponent />
		    <div className="container">
			    <Component {...matchProps} />
		    </div>
	    </div>
	  )}
	  />
	)
}

export default MainLayout
