import { types } from './sessionActions'
import { combineReducers } from 'redux'

const redirectUrl = (state = null, action) => {
	switch (action.type) {
		case types.REGISTER_USER_REDIRECTION: {
			return action.path
		}
		default:
			return state
	}
}

const user = (state = null, action) => {
	switch (action.type) {
		case types.GET_ME:
		case types.LOGIN: {
			return action.payload && action.payload.user
		}
		case types.UPDATE_ME: {
			return {
				...state,
				...action.payload
			}
		}
		case types.LOGOUT: {
			return null
		}
		default:
			return state
	}
}

const userLoading = (state = false, action) => {
	switch (action.type) {
		case types.GET_ME_LOADING: {
			return action.payload && action.payload.loading
		}
		default:
			return state
	}
}

const updateUserLoading = (state = false, action) => {
	switch (action.type) {
		case types.UPDATE_ME_LOADING: {
			return action.payload && action.payload.loading
		}
		default:
			return state
	}
}

export default combineReducers({
	user,
	userLoading,
	updateUserLoading,
	redirectUrl
})