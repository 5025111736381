let sessionAccessToken = null

export const storeAccessToken = (accessToken) => {
	sessionAccessToken = accessToken
	return localStorage.setItem('session:accessToken', accessToken)
}

export const loadAccessToken =  () => {
	sessionAccessToken = localStorage.getItem('session:accessToken')
	return sessionAccessToken
}

export const clearSession = () => {
	sessionAccessToken = null
	localStorage.removeItem('session:accessToken')
}

export const getAccessToken = () => { return sessionAccessToken }
