import React from 'react'

export default () => {
	return (
		<img
		  width={64}
		  height={64}
		  src={require('../resources/img/spinner.svg')}
		  alt="Loader"
		  role="presentation" />
	)
}