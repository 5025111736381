import { flatten } from 'flat'

/* eslint-disable max-len, quotes, quote-props */

export default flatten({
	admin: {
		users: {
			delete: {
				title: 'Supprimer l\'utilisateur',
				content: 'Êtes-vous sûr de vouloir supprimer l\'utilisateur "{email}" ?'
			}
		}
	}
})
