import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Field, reduxForm} from 'redux-form'
import {TextField} from '../../../../components/form/index'
import Loader from '../../../../components/Loader'
import {Button} from 'react-bootstrap'

const validate = (values) => {
	const {
		email,
	} = values
	const errors = {}
	if (!email) {
		errors.email = 'Champ obligatoire'
	}
	return errors
}

class UserForm extends Component {
	render() {
		const {
			error,
			handleSubmit,
			submitting,
			valid
		} = this.props

		return (
		  <form onSubmit={handleSubmit} id={this.props.form}>
			  <fieldset>
				  { error &&
				    <div className="error">{ error }</div>
				  }
				  <div className="row">
					  <div className="col-md-12">
						  <Field name="email" label="Email" maxLength={250} component={TextField} autoComplete="off" />
					  </div>
					  <div className="col-md-12">
						  <Field name="firstname" label="Prénom" maxLength={250} component={TextField} autoComplete="off" />
					  </div>
					  <div className="col-md-12">
						  <Field name="lastname" label="Nom" maxLength={250} component={TextField} autoComplete="off"  />
					  </div>
					  <div className="col-md-12">
						  <Field name="password" label="Password" maxLength={250} component={TextField} type="password" autoComplete="off" />
					  </div>
				  </div>
				  <br />
				  <div className="row">
					  <div className="col-xs-6">
						  <Button onClick={this.props.onCancel} block className="btn btn-cancel">
							  Annuler
						  </Button>
					  </div>
					  <div className="col-xs-6">
						  <Button
							bsStyle="primary"
							block
							type="submit"
							disabled={submitting || !valid}
						  >
								<span>
									Valider
									<span>
										{ submitting && <Loader /> }
									</span>
								</span>
						  </Button>
					  </div>
				  </div>
			  </fieldset>
		  </form>
		)
	}
}

UserForm.propTypes = {
	onCancel: PropTypes.func,
	// redux-form
	handleSubmit: PropTypes.func,
	submitting: PropTypes.bool,
	valid: PropTypes.bool
}

UserForm.defaultProps = {
	submitting: false,
	valid: false
}

export default reduxForm({
	form: 'UserForm',
	validate
})(UserForm)