export const getUserList = (state) => (
  state.webservices.users &&
  state.webservices.users.list &&
  state.webservices.users.list.toArray()
)

export const isLoadingUserList = (state) => (
  state.webservices.users &&
  state.webservices.users.loadingList
)

export const getUserById = (idUser) => (state) => (
  state.webservices.users &&
  state.webservices.users.userById &&
  state.webservices.users.userById.get(idUser)
)

export const isLoadingUserById = (idUser) => (state) => (
  state.webservices.users &&
  state.webservices.users.loadingById &&
  state.webservices.users.loadingById.has(idUser)
)

export const isLoadingForUpdatingUser = (idUser) => (state) => (
  state.webservices.users &&
  state.webservices.users.loadingForUpdating &&
  state.webservices.users.loadingForUpdating.has(idUser)
)

export const isLoadingForDeletingUser = (idUser) => (state) => (
  state.webservices.users &&
  state.webservices.users.loadingForDeleting &&
  state.webservices.users.loadingForDeleting.has(idUser)
)