import {types} from './usersActions'
import {combineReducers} from 'redux'
import {
	generateListReducer,
	generateLoadingByIdReducer,
	generateLoadingReducer,
	generateValueByIdReducer
} from '../reducerUtils'

const list = generateListReducer(types.GET_LIST)
const loadingList = generateLoadingReducer(types.LOADING_GET_LIST)

const userById = generateValueByIdReducer(types.GET_USER_BY_ID)
const loadingById = generateLoadingByIdReducer(types.LOADING_GET_USER_BY_ID)

const loadingForUpdating = generateLoadingByIdReducer(types.LOADING_UPDATE_USER_BY_ID)
const loadingForDeleting = generateLoadingByIdReducer(types.LOADING_DELETE_USER_BY_ID)

export default combineReducers({
	loadingList,
	list,
	userById,
	loadingById,
	loadingForUpdating,
	loadingForDeleting
})
