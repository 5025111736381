import React, {Component} from 'react'
import UserList from './components/UserList'
import {getUserList, isLoadingUserList} from '../../../store/users/usersSelector'
import * as usersActions from '../../../store/users/usersActions'
import {connect} from 'react-redux'
import Loader from '../../../components/Loader'
import {Button, Glyphicon} from 'react-bootstrap'
import EditUserPopin from './components/EditUserPopin'

class UserPage extends Component {

	constructor() {
		super()
		this.state = {
			editPopin: false
		}
	}

	componentWillMount() {
		this.props.loadList()
	}

	openEditPopin = () => {
		this.setState({
			editPopin: true
		})
	}

	closeEditPopin = () => {
		this.setState({
			editPopin: false
		})
	}

	render() {
		const {list, loadingList} = this.props
		return (
			<div>
				<div className="row">
					<div className="col-xs-12">
						<h1>Utilisateurs</h1>
					</div>
					<div className="
					col-md-2 col-md-push-10
					col-xs-4 col-xs-push-8">
						<Button className="btn btn-secondary" block onClick={() => {
							this.openEditPopin()
						}}>
							<Glyphicon glyph="plus"/> Ajouter
						</Button>
						<EditUserPopin open={this.state.editPopin} onClose={this.closeEditPopin} />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						{loadingList ?
						  <Loader />
						  :
						  <UserList users={list}/>
						}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
  loadingList: isLoadingUserList(state),
  list: getUserList(state)
})

export default connect(
  mapStateToProps,
  {
	  loadList: usersActions.loadUserList
  }
)(UserPage)