import {Map, Set, List} from 'immutable'

/**
 * Generateur de reducer pour une map valeur by id
 * S'attend à recevoir une action avec un payload dans le format suivant : {id, value}
 * @param actionType
 * @returns {function(*=, *)}reducer
 */
export const generateValueByIdReducer = (actionType) => (state =  new Map({}), action) => {
	switch (action.type) {
		case actionType: {
			if (!action.payload) {
				return state
			}
			return state.set(action.payload.id, action.payload.value)
		}
		default:
			return state
	}
}

/**
 * Generateur de reducer pour l'expiration d'element by id
 * S'attend à recevoir une action avec un payload dans le format suivant : {id, loading}
 * @param actionType
 * @returns {function(*=, *)}
 */
export const generateLoadingByIdReducer = (actionType) => (state = new Set([]), action) => {
	switch (action.type) {
		case actionType: {
			const key = action.payload.id
			if (action.payload.loading) {
				return state.add(key)
			}
			return state.delete(key)
		}
		default:
			return state
	}
}

/**
 * Generateur de reducer pour une simple value
 * S'attend à recevoir une action avec un payload dans le format suivant : {value}
 * @param actionType
 * @returns {function(*=, *)}
 */
export const generateSimpleValueReducer = (actionType) => (state = null, action) => {
	switch (action.type) {
		case actionType: {
			return action.payload.value
		}
		default:
			return state
	}
}

/**
 * Generateur de reducer pour une liste
 * S'attend à recevoir une action avec un payload dans le format suivant : {value}
 * @param actionType
 * @returns {function(*=, *)}
 */
export const generateListReducer = (actionType) => (state = new List([]), action) => {
	switch (action.type) {
		case actionType: {
			if (action.payload.value && action.payload.value.length) {
				return new List(action.payload.value)
			}
			return new List([])
		}
		default:
			return state
	}
}

/**
 * Generateur de reducer pour le loading avant la fin d'une action
 * S'attend à recevoir une action avec un payload dans le format suivant : {loading}
 * @param actionType
 * @returns {function(*=, *)}
 */
export const generateLoadingReducer = (actionType) => (state = false, action) => {
	switch (action.type) {
		case actionType: {
			return action.payload.loading
		}
		default:
			return state
	}
}

/**
 * Generateur de reducer pour l'expiration d'element by id
 * S'attend à recevoir une action avec un payload dans le format suivant : { id }
 * @param actionType
 * @param cacheDuration
 * @returns {function(*=, *)}
 */
export const generateExpirationDateByIdReducer = (actionType, cacheDuration) => (state = new Map({}), action) => {
	switch (action.type) {
		case actionType: {
			if (!action.payload) {
				return state
			}
			const key = action.payload.id
			return state.set(key, (+new Date() + cacheDuration * 1000))
		}
		default:
			return state
	}
}